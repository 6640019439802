import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { FormulaI } from "../../../../../pojo/RateScheduleI";
import { S25Util } from "../../../../../util/s25-util";
import { S25TimepickerComponent } from "../../../../s25-timepicker/s25.timepicker.component";
import { Debounce } from "../../../../../decorators/debounce.decorator";

@TypeManagerDecorator("s25-ng-formula-builder")
@Component({
    selector: "s25-ng-formula-builder",
    template: `<div *ngIf="init" class="formulaList" [class.pivoted]="isPivoted">
        <h3 class="c-margin-bottom--quarter ngBold" [class.pivoted]="isPivoted">Rate Formulas</h3>
        <p *ngIf="formulas.length === 0" class="no-formulas-msg">No Rate Formulas Added</p>
        <ng-container *ngFor="let formula of formulas; let i = index">
            <div
                *ngIf="formula.status !== 'del'"
                class="formula-entry c-margin-bottom--quarter"
                [class.pivoted]="isPivoted"
            >
                <div class="thread-top" [ngClass]="{ 'not-first': i > 0 }"></div>
                <div class="thread-bottom"></div>
                <fieldset [class.pivoted]="isPivoted">
                    <legend>Time Range</legend>
                    <label class="c-margin-right--half"
                        ><input
                            type="radio"
                            value="none"
                            name="{{ i }}-{{ radioNameHash }}"
                            [checked]="formula.rangeType === 'none'"
                            [(ngModel)]="formula.rangeType"
                            (ngModelChange)="handleTypeChange(formula, 'none')"
                        />None</label
                    >
                    <label class="c-margin-right--half"
                        ><input
                            type="radio"
                            value="time"
                            name="{{ i }}-{{ radioNameHash }}"
                            [checked]="formula.rangeType === 'time'"
                            [(ngModel)]="formula.rangeType"
                            (ngModelChange)="handleTypeChange(formula, 'time')"
                        />Specific Times
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="duration"
                            name="{{ i }}-{{ radioNameHash }}"
                            [checked]="formula.rangeType === 'duration'"
                            [(ngModel)]="formula.rangeType"
                            (ngModelChange)="handleTypeChange(formula, 'duration')"
                        />Specific Duration</label
                    >
                    <div *ngIf="errorMsgMap[i]?.timeErrorMsg" class="error-message">
                        {{ errorMsgMap[i].timeErrorMsg }}
                    </div>
                    <div *ngIf="formula.rangeType === 'time'" class="time-range c-margin-top--half">
                        <label>
                            Start Time
                            <s25-timepicker
                                [(modelValue)]="formula.durStart"
                                (modelValueChange)="normalizeTime(formula)"
                            ></s25-timepicker>
                        </label>
                        <label>
                            End Time
                            <s25-timepicker
                                [(modelValue)]="formula.durEnd"
                                (modelValueChange)="normalizeTime(formula)"
                            ></s25-timepicker>
                        </label>
                    </div>
                    <div *ngIf="formula.rangeType === 'duration'" class="c-margin-top--half">
                        <div>Minimum:</div>
                        <label class="c-margin-left--half"
                            >Days
                            <input
                                class="c-input c-margin-right--half"
                                type="number"
                                min="0"
                                max="65536"
                                [(ngModel)]="formula.durStart.days"
                                (ngModelChange)="setStatus(formula)"
                        /></label>
                        <label
                            >Hours
                            <input
                                class="c-input c-margin-right--half"
                                type="number"
                                min="0"
                                max="23"
                                [(ngModel)]="formula.durStart.hours"
                                (ngModelChange)="setStatus(formula)"
                        /></label>
                        <label>
                            Minutes
                            <input
                                class="c-input c-margin-right--half"
                                type="number"
                                min="0"
                                max="59"
                                [(ngModel)]="formula.durStart.minutes"
                                (ngModelChange)="setStatus(formula)"
                            />
                        </label>
                        <div class="c-margin-top--quarter">Maximum:</div>
                        <label class="c-margin-left--half"
                            >Days
                            <input
                                class="c-input c-margin-right--half"
                                type="number"
                                min="0"
                                max="65536"
                                [(ngModel)]="formula.durEnd.days"
                                (ngModelChange)="setStatus(formula)"
                        /></label>
                        <label
                            >Hours
                            <input
                                class="c-input c-margin-right--half"
                                type="number"
                                min="0"
                                max="23"
                                [(ngModel)]="formula.durEnd.hours"
                                (ngModelChange)="setStatus(formula)"
                        /></label>
                        <label>
                            Minutes
                            <input
                                class="c-input c-margin-right--half"
                                type="number"
                                min="0"
                                max="59"
                                [(ngModel)]="formula.durEnd.minutes"
                                (ngModelChange)="setStatus(formula)"
                            />
                        </label>
                    </div>
                </fieldset>
                <fieldset class="formula-wrapper" [class.pivoted]="isPivoted">
                    <legend
                        [ngClass]="{
                            'error-true': errorMsgMap[i]?.formulaErrorMsg,
                            'c-margin-top--half': isPivoted,
                        }"
                    >
                        Formula
                    </legend>
                    <div *ngIf="errorMsgMap[i]?.formulaErrorMsg" class="error-message">
                        {{ errorMsgMap[i].formulaErrorMsg }}
                    </div>
                    <textarea
                        [(ngModel)]="formula.expression"
                        (ngModelChange)="setStatus(formula)"
                        aria-label="Rate Formula"
                        class="cn-form__control"
                        [ngClass]="{ 'large-area': formula.rangeType === 'duration' }"
                    ></textarea>
                </fieldset>
                <div class="button-group" [class.pivoted]="isPivoted">
                    <button class="aw-button aw-button--outline" (click)="save(formula, i)">Add</button>
                    <button
                        *ngIf="formulas.length > 1"
                        class="aw-button aw-button--danger--outline"
                        (click)="delete(formula, i)"
                    >
                        Remove
                    </button>
                </div>
            </div>
        </ng-container>
        <button *ngIf="showAddButton" class="c-textButton" (click)="addFormula()">
            <svg class="c-svgIcon" role="img">
                <title>Add Formula</title>
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#plus"
                ></use>
            </svg>
            Add Formula
        </button>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25FormulaBuilderComponent implements OnInit, OnDestroy {
    @Input() formulas: FormulaI[];
    @Input() isEdit: boolean = false;
    @Input() sheetIndex: number;
    @Input() validateFunc: (formula: FormulaI, index: number) => { isValid: boolean; errorMap: FormulaErrorObj };

    @ViewChild(S25TimepickerComponent) timepickerComp: S25TimepickerComponent;

    init: boolean;
    maxBreakPoint: number = 0;
    showAddButton: boolean;
    errorMsgMap: { [key: number]: { [key: string]: string } } = {};
    isPivoted: boolean;
    radioNameHash: number;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.formulas[0]?.status !== "new") {
            this.showAddButton = true;
        }

        this.radioNameHash = Math.floor(Math.random() * 1000);

        this.errorMsgMap[0] = { formulaErrorMsg: "", timeErrorMsg: "" };

        this.formulas.forEach((formula) => {
            this.maxBreakPoint = Math.max(this.maxBreakPoint, parseInt(formula.break_point as string));
            formula.date = { date: formula.startDt ?? formula.effectiveDate };

            this.setRangeDisplay(formula);
        });

        this.isPivoted = window.innerWidth <= 1290;

        this.init = true;
        this.cd.detectChanges();
    }

    @HostListener("window:resize")
    @Debounce(100)
    onWindowResize() {
        this.isPivoted = window.innerWidth <= 1290;
        this.cd.detectChanges();
    }

    setRangeDisplay(formula: FormulaI) {
        if (formula.durStart === formula.durEnd) formula.rangeType = "none";

        if (formula.rangeType === "time") {
            formula.startTime = S25Util.date.toTimeStr(formula.durStart);
            formula.endTime = S25Util.date.toTimeStr(formula.durEnd);
        }
    }

    handleTypeChange(formula: FormulaI, rangeType: FormulaI["rangeType"]) {
        formula.rangeType = rangeType;

        if (rangeType === "none") {
            formula.durStart = formula.durEnd = "";
        } else if (rangeType === "time") {
            formula.durStart = formula.durEnd = S25Util.date.parse("00:00");
        } else if (rangeType === "duration") {
            formula.durStart = { days: 0, hours: 0, minutes: 0 };
            formula.durEnd = { days: 0, hours: 0, minutes: 0 };
        }

        this.setStatus(formula);
    }

    addFormula() {
        this.formulas.push({
            durStart: "",
            durEnd: "",
            rangeType: "none",
            expression: "",
            status: "new",
            break_point: this.maxBreakPoint + 1,
        });

        this.maxBreakPoint += 1;
        this.showAddButton = false;
        this.cd.detectChanges();
    }

    setErrorMsg(errorObj: FormulaErrorObj, index: number) {
        this.errorMsgMap[index] = errorObj;
        this.cd.detectChanges();
    }

    save(formula: FormulaI, index: number) {
        const { isValid, errorMap } = this.validateFunc(formula, index);

        if (isValid) {
            this.setRangeDisplay(formula);
            this.errorMsgMap[index] = { timeErrorMsg: "", formulaErrorMsg: "" };

            this.showAddButton = true;
            this.cd.detectChanges();
            return;
        }

        this.setErrorMsg(errorMap, index);
    }

    delete(formula: FormulaI, index: number) {
        if (formula.status === "new") {
            S25Util.array.inplaceRemoveByProp(this.formulas, "break_point", formula.break_point);
        } else {
            formula.status = "del";
        }

        this.errorMsgMap = {};
        this.showAddButton = this.formulas[0].expression !== "";
        this.cd.detectChanges();
    }

    setStatus(formula: FormulaI) {
        if (this.isEdit) formula.status = "mod";
    }

    normalizeTime(formula: FormulaI) {
        formula.durStart = S25Util.date.toTimeStr(formula.durStart); //S25Util.date.parse(formula.durStart);
        formula.durEnd = S25Util.date.toTimeStr(formula.durEnd);
        this.setStatus(formula);
    }

    ngOnDestroy() {
        this.errorMsgMap = {};
        this.cd.detectChanges();
    }
}

export type FormulaErrorObj = {
    timeErrorMsg: string;
    formulaErrorMsg: string;
};
