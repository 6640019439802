import { formatNumber } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { DropDownItem } from "../../../../pojo/DropDownItem";
import { TaxesService } from "../../../../services/taxes.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-tax-schedules")
@Component({
    selector: "s25-ng-tax-schedules",
    template: `<div *ngIf="this.init">
        <div class="c-margin-bottom--single pricingTaxSchedules">
            <h2 class="c-margin-bottom--single">Tax Schedules</h2>
            <label for="taxDropdown" class="ngBold c-margin-bottom--quarter"> Select a Tax Schedule </label>
            <s25-ng-tax-schedules-dropdown
                [(chosen)]="this.tax"
                (chosenChange)="this.getData()"
            ></s25-ng-tax-schedules-dropdown>

            <button
                *ngIf="!this.tax"
                class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                (click)="createNewTaxSchedule()"
            >
                Create New
            </button>

            <div *ngIf="this.form" class="c-margin-top--single">
                <label for="taxName" class="ngBold c-margin-right--quarter">Name</label>
                <input class="s25-input" type="text" id="taxName" [(ngModel)]="this.model.data.tax_name" required />
                <div *ngIf="errMsg.taxName" class="ngRed">{{ errMsg.taxName }}</div>

                <div class="ngBold c-margin-top--single c-margin-bottom--quarter ngBlock">Rates</div>
                <div *ngFor="let rate of this.model.data.rate" class="ngTableRow">
                    <ng-container *ngIf="rate.status != 'del'">
                        <div
                            (click)="this.removeRate(rate)"
                            (enter)="this.removeRate(rate)"
                            tabindex="0"
                            class="ngTableCell ng-scope c-margin-right--half"
                            role="button"
                        >
                            <svg class="c-svgIcon">
                                <title>Remove Rate</title>
                                <use
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                ></use>
                            </svg>
                        </div>
                        <div class="c-margin-left--single">
                            <div class="ngTableCell">
                                <s25-datepicker
                                    [inputLabel]="'Start Date'"
                                    [inputId]="'startDate'"
                                    [(modelValue)]="rate.startDateModel"
                                    [inline]="false"
                                    [multipleDate]="false"
                                    (onModelValueChange)="onChange(rate)"
                                ></s25-datepicker>
                            </div>
                            <div class="ngTableCell">
                                <label for="tax" class="ngBold c-margin-right--quarter  c-margin-left--single"
                                    >Tax Rate</label
                                >
                                <s25-ng-editable-percentage
                                    id="tax"
                                    [(val)]="rate.percentage"
                                ></s25-ng-editable-percentage>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <button class="aw-button aw-button--primary" (click)="addRate()">New Rate</button>
                <div *ngIf="errMsg.rates" class="ngRed">{{ errMsg.rates }}</div>

                <label class="ngBold c-margin-top--single c-margin-bottom--quarter ngBlock">Rate Groups</label>
                <p class="ngFinePrint c-margin-bottom--half">Select Rate Group(s) associated with this tax schedule.</p>
                <div *ngFor="let group of this.model.data.rate_group" class="c-margin-top--half c-margin-bottom--half">
                    <ng-container *ngIf="group.status != 'del'">
                        <span
                            (click)="this.removeRate(group)"
                            (enter)="this.removeRate(group)"
                            tabindex="0"
                            class="ngCpointer ng-scope c-margin-right--half"
                            role="button"
                        >
                            <svg class="c-svgIcon">
                                <title>Remove rate group {{ group.rate_group_name }}</title>
                                <use
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"
                                ></use>
                            </svg>
                        </span>
                        {{ group.rate_group_name }}
                    </ng-container>
                </div>

                <!--<button class="aw-button aw-button-primary" (click)="addRateGroup()">Add Rate Group</button>-->
                <s25-ng-dropdown-rate-group
                    [(chosen)]="this.newRateGroups"
                    (chosenChange)="this.addRateGroups()"
                    [resetSelectedOnCleanup]="true"
                ></s25-ng-dropdown-rate-group>

                <div class="c-displayBlock c-padding-bottom--double c-margin-top--single buttons">
                    <button
                        class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                        (click)="save()"
                    >
                        {{ this.loading ? "Saving...." : "Save" }}
                    </button>
                    <button
                        class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                        (click)="cancel()"
                    >
                        Cancel
                    </button>
                    <button class="aw-button aw-button--danger--outline c-margin-top--single" (click)="delete()">
                        Delete Tax Schedule
                    </button>
                </div>

                <div
                    *ngIf="this.message"
                    class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
                    role="alert"
                >
                    <div class="cn-alert__icon cn-icon" name="alert--info">
                        <svg class="cn-svg-icon" role="img">
                            <title>Success Alert</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                            ></use>
                        </svg>
                    </div>
                    <div class="cn-alert__label">
                        <span>{{ this.message }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>`,
    styles: `
        s25-ng-dropdown-rate-group {
            width: 20em;
            display: block;
        }

        ::ng-deep s25-ng-tax-schedules s25-datepicker .form-group > label {
            display: block;
        }

        ::ng-deep s25-ng-tax-schedules s25-ng-editable-percentage > div {
            margin-left: 0.5em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25TaxSchedulesComponent implements OnInit {
    @Input() tax?: DropDownItem;
    @Input() mode?: "create" | "copy" | "edit" = "edit";

    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    model: any = {};
    init = false;
    isDirty = false;
    loading = false;
    newRateGroups: DropDownItem[];
    form = false;
    message = "";
    errMsg = { taxName: "", rates: "", rateGroups: "" };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (this.tax) this.getData();
        if (this.mode === "create") this.createNewTaxSchedule();
        this.done();
    }

    done(): void {
        this.init = true;
        this.isDirty = false;
        this.cd.detectChanges();
    }

    getData() {
        this.reset();
        if (this.tax && this.tax.itemId) {
            TaxesService.getTax(S25Util.parseInt(this.tax.itemId)).then((data) => {
                this.model.data = data;
                this.model.data.rate = data.rate.map((rate: any) => {
                    return {
                        percentage: formatNumber(rate.percentage * 100, "en-US", "1.1-3"),
                        version_nbr: rate.version_nbr,
                        status: rate.status,
                        startDateModel: { date: S25Util.date.parse(rate.start_dt), showToday: true },
                    };
                });
                if (this.mode === "copy") {
                    this.model.data.tax_id = null; // No ID => create new
                    this.model.data.tax_name += " copy";
                }

                this.model.initData = S25Util.deepCopy(this.model.data);
                this.form = true;
                this.done();
            });
        } else {
            //probably creating new,
            this.model.data = { rate: [], rate_group: [] };
            this.done();
        }
    }

    createNewTaxSchedule() {
        this.model.data = {};
        this.form = true;
        this.cd.detectChanges();
    }

    addRate() {
        let today = new Date();
        let dateModel = {
            date: S25Util.date.createDate(today.getFullYear(), today.getMonth() + 1, today.getDate()),
            showToday: true,
        };
        this.model.data.rate = S25Util.array.forceArray(this.model.data.rate);
        this.model.data.rate.push({
            startDateModel: dateModel,
            status: "new",
            percentage: "0",
        });
        this.reset();
        this.cd.detectChanges();
    }

    removeRate(rate: any) {
        rate.status = "del";
        this.reset();
        this.cd.detectChanges();
    }

    addRateGroups() {
        this.model.data.rate_group = S25Util.array.forceArray(this.model.data.rate_group);
        this.newRateGroups.forEach((rg) => {
            let newGroup = {
                rate_group_name: rg.itemName,
                rate_group_id: rg.itemId,
                status: "new",
            };

            S25Util.array.inplacePushByProp(
                this.model.data.rate_group,
                "rate_group_id",
                newGroup.rate_group_id,
                newGroup,
            );
        });

        this.newRateGroups = [];
        this.reset();
        this.cd.detectChanges();
    }

    onChange(item: any) {
        this.reset();
        if ("est" === item.status) item.status = "mod";
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();

        if (this.validate()) {
            let payload = S25Util.deepCopy(this.model.data);
            let versions = payload.rate?.map((rate: any) => {
                return S25Util.parseInt(rate.version_nbr || 0);
            });
            let maxVersion = Math.max(...versions) || 1;
            payload.rate = S25Util.array.forceArray(payload.rate);
            payload.rate = payload.rate?.map((rate: any) => {
                let version = rate.version_nbr || ++maxVersion;
                return {
                    percentage: formatNumber(rate.percentage / 100, "en-US", "1.1-5"),
                    version_nbr: S25Util.toStr(version),
                    status: rate.status,
                    start_dt: rate.startDateModel.date
                        ? S25Util.date.toS25ISODateStr(rate.startDateModel.date)
                        : S25Util.date.toS25ISODateStr(rate.startDateModel),
                };
            });

            TaxesService.saveTax(this.model.data.tax_id, payload).then(
                (resp) => {
                    S25Util.setOnObjectDeep(this.model.data, "status", "est");
                    this.loading = false;
                    this.message = "Success!";
                    this.done();
                    this.saved.emit();
                },
                (err) => {
                    S25Util.showError(err);
                    this.loading = false;
                    this.done();
                    this.saved.emit();
                },
            );
        } else {
            this.loading = false;
            this.done();
        }
    }

    reset() {
        this.message = "";
        this.errMsg = { taxName: "", rates: "", rateGroups: "" };
    }

    cancel() {
        this.form = false;
        this.tax = null;
        this.cd.detectChanges();
        this.cancelled.emit();
    }

    delete() {
        TaxesService.delete(this.tax.itemId).then((resp) => {
            this.form = false;
            this.tax = {};
            this.init = false;
            this.cd.detectChanges();
            this.getData();
        });
    }

    validate() {
        this.reset();
        let isValid = true;
        if (!this.model?.data?.tax_name) {
            this.errMsg.taxName = "Please enter a name";
            isValid = false;
        }
        if (!this.model?.data?.rate || this.model?.data?.rate?.filter((r: any) => r.status !== "del") < 1) {
            this.errMsg.rates = "Please enter at least one tax rate";
            isValid = false;
        }
        return isValid;
    }
}
