import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { Bind } from "../../../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { TaxesService } from "../../../../services/taxes.service";
import { ModalService } from "../../../modal/modal.service";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { CacheRepository } from "../../../../decorators/cache.decorator";
import { GenericTableListComponent } from "../../../s25-table/generics/generic.table.list.component";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-tax-schedules-list")
@Component({
    selector: "s25-ng-tax-schedules-list",
    template: `
        <ng-container *ngIf="isInit">
            <h3 class="c-margin-bottom--single">Tax Schedules</h3>
            <div class="header">
                <button class="aw-button aw-button--primary" (click)="onCreate()">Add Tax Schedule</button>
            </div>
            <s25-ng-table
                [dataSource]="tableConfig"
                [hasFilter]="true"
                [columnSortable]="true"
                [hasRefresh]="true"
            ></s25-ng-table>
        </ng-container>
    `,
    styles: `
        .header {
            margin-bottom: 1em;
        }

        ::ng-deep s25-ng-tax-schedules-list table {
            margin-left: 0 !important;
        }

        ::ng-deep s25-ng-tax-schedules-list table li {
            list-style-type: initial !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25TaxSchedulesListComponent implements OnInit {
    isInit = false;
    tableConfig: Table.DataSource;

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.initTableConfig();

        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    initTableConfig() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "name",
                    header: "Name",
                },
                {
                    id: "currentRate",
                    header: "Current Rate",
                },
                {
                    id: "groups",
                    header: "Rate Groups",
                    content: { component: GenericTableListComponent },
                },
                GenericTableButtonComponent.Column("Copy", this.onCopy, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEdit, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDelete, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) {
            CacheRepository.invalidateByService("TaxesService", "getTaxes");
            CacheRepository.invalidateByService("PricingService", "getRatesAndTaxes");
        }
        const taxes = await TaxesService.getTaxes();
        return {
            rows:
                taxes?.map((tax) => {
                    const rateGroups: Table.Cell[] = tax.rate_groups.map((group) => ({ text: group.name }));
                    return {
                        id: tax.tax_id,
                        name: tax.tax_name,
                        cells: {
                            name: { text: tax.tax_name },
                            currentRate: { text: tax.current_rate ? `${(tax.current_rate * 100).toFixed(2)}%` : "" },
                            groups: { inputs: { items: rateGroups }, textValue: rateGroups.join(" ") },
                        },
                    };
                }) || [],
        };
    }

    @Bind
    onCreate() {
        ModalService.modal("edit-tax-schedule", {
            mode: "create",
            title: `Create Tax Schedule`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onCopy(row: Table.Row) {
        ModalService.modal("edit-tax-schedule", {
            id: row.id,
            mode: "copy",
            title: `Copy Tax Schedule: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onEdit(row: Table.Row) {
        ModalService.modal("edit-tax-schedule", {
            id: row.id,
            title: `Edit Tax Schedule: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    async onDelete(row: Table.Row, instance: GenericTableButtonComponent) {
        const dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this tax schedule?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        instance.isLoading = true;
        await TaxesService.delete(row.id);
        instance.isLoading = false;
        return this.tableComponent.refresh();
    }
}
